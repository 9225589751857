import * as React from "react"
import { Link } from "gatsby"
import Typical from 'react-typical'
const Banner = (props) => {

    return (



        <section className = {props.className + " home_banner"} >

            <p  dangerouslySetInnerHTML={{ __html:props.sticky_first }}></p>
              
                <p>{props.sticky_second}</p>

               
  { props.title ? 
        <Typical
            steps={props.title}
            loop={Infinity}
            wrapper="p"
          /> 
          : 
          null
        }
        
          {props.button ? 
          
          <div>
          <Link className = "hire_va" to ="https://appweb.estorecompany.com/website-discovery-call-1104" >{props.button}</Link>
          
          </div>
          : 
          null
          }
          
    </section>




    )



}

export default Banner;